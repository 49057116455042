import React, { Dispatch, ReactElement, SetStateAction, useEffect, useRef } from 'react';
import {
	formatCsvColumn,
	formatCsvDataToColumns,
	MultipartResponse,
} from '../../helpers/multipart-response.helper';
import { DataframeDataRetrievalResponse, TDataframeOrder, TDateRange } from '../../api/types';
import { TableComposable, Tbody, Td, Th, Thead, ThProps, Tr } from '@patternfly/react-table';
import { TNewDataframeOrder } from '../../api/dataframes/Dataframes';
import { SortTypes } from '../../types/common/sort-types';
import Loader from '../util/Loader';
import { Bullseye, EmptyState, EmptyStateVariant, Title } from '@patternfly/react-core';
import { TUnitType } from '../../api/analytics/UnitType';
import { IIndexable } from '../../types/general';
import { DraggableMenuItemData } from '../../types/databuilder/databuilder';
import { DateRange } from '../../api/date-period-selector/DateRange';
import { useApplication } from '../../../src/components/user/ApplicationProvider';
import { OptionsBuilderItemTypes } from '../../types/dataframes/options-builder-item-types';
import './Preview.scss';
import { numberFormatter } from '../../helpers/number-formatter';
import { containerDimensions } from '../../helpers/container-dimensions.helper';

export type IPreviewProps = {
	data?: MultipartResponse<DataframeDataRetrievalResponse>;
	facts?: DraggableMenuItemData[];
	rows?: DraggableMenuItemData[];
	columns?: DraggableMenuItemData[];
	unitTypes?: TUnitType[];
	order?: TNewDataframeOrder | undefined;
	setOrder?: Dispatch<SetStateAction<TDataframeOrder | TNewDataframeOrder | undefined>>;
	isLoading: boolean;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	setSelectedUnitType?: Dispatch<SetStateAction<TUnitType | undefined>>;
	ApplyDrilldownDrillIn?: (name: string, isKMF: boolean) => void;
};

const Preview = (props: IPreviewProps): ReactElement => {
	const [columns, setColumns] = React.useState<string[]>([]);
	const [formattedData, setFormattedData] = React.useState<IIndexable[]>([]);
	const [activeSortIndex, setActiveSortIndex] = React.useState(0);
	const [activeSortDirection, setActiveSortDirection] = React.useState<
		'asc' | 'desc' | undefined
	>('asc');
	const { currentDatePeriods } = useApplication();
	const defaultDatePeriod =
		currentDatePeriods.find((dp) => dp.period === 3) ?? (DateRange.Default() as TDateRange);

	const previousSelectedStartPeriod = localStorage.getItem('currentSelectedStartPeriod');
	const previousSelectedEndPeriod = localStorage.getItem('currentSelectedEndPeriod');
	const hasPreviouslySelectedPeriod =
		previousSelectedStartPeriod != null || previousSelectedEndPeriod != null;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const previouslySelectedStartDate: TDateRange | null =
		hasPreviouslySelectedPeriod && previousSelectedStartPeriod
			? JSON.parse(previousSelectedStartPeriod)
			: null;
	const periodId =
		hasPreviouslySelectedPeriod && previouslySelectedStartDate
			? previouslySelectedStartDate.period
			: defaultDatePeriod?.period ?? 0;
	const [pivotRows, setPivotRows] = React.useState<JSX.Element[]>([]);
	const containerRef = useRef<HTMLDivElement>(null);
	const containerDims = containerDimensions(containerRef);

	useEffect(() => {
		if (props.data) {
			if (props.data.json) {
				if (props.data.csvData) {
					let formattedData: IIndexable[] = formatCsvDataToColumns(
						props.data.json.columns,
						props.data.csvData,
						periodId
					);

					if (formattedData) {
						formattedData = formattedData.map((item: IIndexable): IIndexable => {
							const keys: string[] = Object.keys(item);
							const isSummaryRow = keys.some(
								(key) => item[key] && (item[key] as string).includes('Summary')
							);

							keys.map((key) => {
								const fact = props.facts?.find((fact) => {
									const keyToUse = key.toLowerCase().replace(/\s/g, '');
									const isAlias =
										keyToUse ===
										fact.data?.alias?.toLowerCase().replace(/\s/g, '');
									const isTitle =
										keyToUse ===
										fact.data?.title.toLowerCase().replace(/\s/g, '');
									return isAlias || isTitle;
								});

								if (fact) {
									const unitType: TUnitType | undefined = props.unitTypes
										? props.unitTypes?.find(
												(type: TUnitType) => type.id == fact?.data?.unitType
										  )
										: undefined;
									if (item[key] && item[key] != '') {
										if (!isNaN(item[key] as number)) {
											if (unitType) {
												if (unitType.name.toLowerCase() === 'percentage') {
													item[key] = numberFormatter(
														parseFloat(item[key] as string) * 100,
														fact.data?.numDecimals
													);
												} else {
													item[key] = numberFormatter(
														parseFloat(item[key] as string),
														fact.data?.numDecimals
													);
												}

												item[key] = unitType.prefix
													? unitType.prefix + (item[key] as string)
													: unitType.suffix
													? (item[key] as string) + unitType.suffix
													: (item[key] as string);
											}
										}
									} else {
										if (fact.data?.defaultWhenNull) {
											item[key] = fact.data?.defaultWhenNull;
										}
									}
								} else if (key.includes('PercentofTotal')) {
									if (!isNaN(item[key] as number)) {
										item[key] = `${numberFormatter(item[key] as number)}%`;
									}
								}

								if (isSummaryRow && item[key] && item[key] != '') {
									item[key] = <b>{item[key]}</b>;
								}
							});

							return item;
						});

						if (props.columns && props.columns.length) {
							const rows: JSX.Element[] = [];

							// Build additional rows of column headers
							props.columns.forEach((col, colIndex) => {
								if (col.data) {
									const headers: JSX.Element[] = [
										<Th colSpan={props.rows ? props.rows.length : 1}>
											{col.data.title}
										</Th>,
									];
									const currentKey = col.data.title.replace(/\s+/g, '');
									const currentUniqueColValues: string[] =
										getUniqueColumnValuesByKey(
											currentKey,
											formattedData
										) as string[];
									let previousKey: string | undefined = undefined;
									let previousUniqueValues: string[] | null = null;
									let nextKey: string | undefined = undefined;
									let nextUniqueValues: string[] | null = null;

									if (props.columns) {
										if (colIndex > 0) {
											previousKey = props.columns[
												colIndex - 1
											].data?.title.replace(/\s+/g, '');
											previousUniqueValues = getUniqueColumnValuesByKey(
												previousKey!,
												formattedData
											) as string[];
										}

										if (props.columns[colIndex + 1]) {
											nextKey = props.columns[
												colIndex + 1
											].data?.title.replace(/\s+/g, '');
											nextUniqueValues = getUniqueColumnValuesByKey(
												nextKey!,
												formattedData
											);
										}
									}

									if (previousUniqueValues && previousUniqueValues.length > 1) {
										previousUniqueValues.forEach((prevVal) => {
											currentUniqueColValues.forEach((val) => {
												headers.push(
													<Th
														colSpan={
															nextUniqueValues
																? nextUniqueValues.length
																: 1
														}
													>
														{val}
													</Th>
												);
											});
										});
									} else {
										currentUniqueColValues.forEach((val) => {
											headers.push(
												<Th
													colSpan={
														nextUniqueValues
															? nextUniqueValues.length
															: 1
													}
												>
													{val}
												</Th>
											);
										});
									}

									rows.push(<Tr>{headers}</Tr>);
								}
							});

							setPivotRows(rows);
						} else {
							setPivotRows([]);
						}

						setFormattedData(formattedData);
						setColumns(props.data.json.columns);
					}
				} else {
					setFormattedData([]);
				}

				if (props.order) {
					let item = props.facts?.find((fact) => {
						const factDataId = fact.data?.id ?? 0;
						return (
							fact.data &&
							factDataId == props.order?.entity_id &&
							fact.entityType == props.order?.entity_type
						);
					});

					if (!item) {
						item = props.rows?.find((row) => {
							return (
								row.data &&
								row.data.id == props.order?.entity_id &&
								row.entityType == props.order?.entity_type
							);
						});
					}
					setActiveSortDirection(
						props.order.direction === SortTypes.Asc ? 'asc' : 'desc'
					);

					const title =
						item?.data?.title === 'Date Range' ? 'Date Series' : item?.data?.title;

					setActiveSortIndex(
						props.data.json.columns.findIndex((col: any) => col === title)
					);
				}
			}
		}
	}, [props.data, props.setSelectedUnitType]);

	useEffect(() => {
		setOrderObject();
	}, [activeSortIndex, activeSortDirection]);

	const getUniqueColumnValuesByKey = <T, K extends keyof T>(key: K, array: T[]): T[K][] => {
		const uniqueValues = new Set<T[K]>();
		array.forEach((item) => uniqueValues.add(item[key]));
		return Array.from(uniqueValues);
	};

	const getSortParams = (columnIndex: number): ThProps['sort'] => ({
		sortBy: {
			index: activeSortIndex,
			direction: activeSortDirection,
			defaultDirection: 'asc',
		},
		onSort: (event: React.MouseEvent, index: number, direction: 'asc' | 'desc' | undefined) => {
			setActiveSortIndex(index);

			//fix for if the previous sort dir is the same as the requested sort dir set to opposite dir
			if (direction?.toUpperCase() == props.order?.direction) {
				direction = direction === 'asc' ? 'desc' : 'asc';
			}
			setActiveSortDirection(direction);
		},
		columnIndex,
	});

	const setOrderObject = () => {
		if (props.setOrder) {
			let item = props.facts?.find((fact) => {
				return fact.data?.title === columns[activeSortIndex];
			});

			if (!item) {
				item = props.rows?.find((row) => {
					return row.data?.title === columns[activeSortIndex];
				});

				if (!item) {
					item = props.rows?.find((row) => {
						return row.entityType == OptionsBuilderItemTypes.DateSeries;
					});
				}
			}

			if (
				item &&
				(props.order?.direction !== activeSortDirection?.toUpperCase() ||
					item.data?.id !== props.order?.entity_id)
			) {
				const newOrder = { ...props.order } as TNewDataframeOrder;
				newOrder.direction = activeSortDirection?.toUpperCase() ?? '';
				newOrder.entity_id = item.data?.id ? +item.data.id : 0;
				newOrder.entity_type = item.entityType;
				newOrder.useSequence = item.data?.useSequence ?? false;
				props.setOrder(newOrder);
			}
		}
	};

	const noData = (
		<Tr>
			<Td colSpan={columns.length}>
				<Bullseye>
					<EmptyState variant={EmptyStateVariant.small}>
						<Title
							headingLevel="h2"
							size="lg"
						>
							No results found
						</Title>
					</EmptyState>
				</Bullseye>
			</Td>
		</Tr>
	);

	const getColumns = () => {
		return columns.map((col, index) => {
			const isPercentageCol = col.replace(/\s+/g, '').includes('PercentofTotal');
			const sortParams = getSortParams(index);
			if (pivotRows.length && props.rows) {
				if (index > props.rows.length - 1) {
					return <Th>{col}</Th>;
				}
			}
			return isPercentageCol ? <Th>{col}</Th> : <Th sort={sortParams}>{col}</Th>;
		});
	};

	const getCells = (row: IIndexable) => {
		return columns.map((col, index) => {
			return (
				<Td
					dataLabel={col}
					className={`${props.ApplyDrilldownDrillIn ? 'cursor' : ''}`}
					onClick={() => {
						applyTableDrilldown(
							row[formatCsvColumn(col)] as string,
							props.facts && props.facts[0].data?.title == col
						);
					}}
				>
					{pivotRows.length ? row[Object.keys(row)[index]] : row[formatCsvColumn(col)]}
				</Td>
			);
		});
	};

	const applyTableDrilldown = (filterValue: string, isKMF = false) => {
		props.ApplyDrilldownDrillIn && props.ApplyDrilldownDrillIn(filterValue, isKMF);
	};

	return (
		<div
			className="preview-table-container"
			ref={containerRef}
		>
			<div
				className="scroll-container"
				style={{ maxWidth: containerDims?.width }}
			>
				{props.isLoading ? (
					<Loader />
				) : (
					<TableComposable>
						<Thead className="sticky-header">
							{pivotRows}
							<Tr>{getColumns()}</Tr>
						</Thead>
						<Tbody>
							{formattedData.length > 0
								? formattedData.map((row, rowIndex) => {
										return <Tr key={rowIndex}>{getCells(row)}</Tr>;
								  })
								: noData}
						</Tbody>
					</TableComposable>
				)}
			</div>
		</div>
	);
};

export default Preview;
